import { Navbar } from 'flowbite-react';

export const Header = () => {
  return (
    <Navbar
      fluid={true}
      className="bg-slate-600 dark:bg-slate-800 dark:border-slate-700 border-slate-800"
    >
      <Navbar.Brand to="/" href="/">
        <span className="self-center whitespace-nowrap text-xl font-semibold text-white">
          Revrite.ai
        </span>
      </Navbar.Brand>
      {/* <Navbar.Toggle /> */}
      {/* <Navbar.Collapse> */}
      {/* <Navbar.Link href="/pricing">Pricing</Navbar.Link>
        <Navbar.Link href="/contact">Contact</Navbar.Link> */}
      {/* </Navbar.Collapse> */}
    </Navbar>
  );
};
